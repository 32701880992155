import { Component, computed, EventEmitter, Input, Output, output } from '@angular/core'
import { AuthMode } from '@naturalcycles/shared'
import { isSignupMode } from '@src/app/core/util/authMode.util'
import { AuthProviderType } from '@src/app/shared/typings/enum/auth'
import { AuthProviderResult } from '@src/app/shared/typings/interfaces/user-auth'

@Component({
  template: '',
  standalone: false,
})
export class AuthProviderComponent {
  @Input()
  public mode = AuthMode.login

  @Input()
  public provider = AuthProviderType.none

  public didChangeProvider = output<AuthProviderType>()

  // TODO [2025-03-24] check if this issue still occurs and switch back to signal if possible https://github.com/angular/angular/issues/19826
  @Output()
  public authResult = new EventEmitter<AuthProviderResult>()

  protected isSignupMode = computed(() => isSignupMode(this.mode))
}
