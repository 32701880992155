<div>
  @if (showBackArrow() && provider !== 'none' && !showConsent) {
    <app-button class="auth__back" (click)="changeProvider('none')" />
  }
  @if (isSignupMode() && !showConsent) {
    <div
      [class]="{
        authHeader__emailProviderActive: provider === 'email',
        authHeader___asModal: centeredHeading(),
      }"
    >
      @if (hasStartedQuiz()) {
        <h3>
          {{ 'txt-take-control' | translate }}
        </h3>
        <p>
          {{ 'txt-your-setup-is-saved' | translate }}
        </p>
      } @else {
        <div class="page-title">
          {{ 'auth-signup-or-login' | translate }}
        </div>
      }
    </div>
  }
  <div [class]="{ authProviders: true, authProviders__hidden: showConsent }">
    <app-email-auth
      [mode]="mode()"
      [authError]="authError"
      (authResult)="collectConsentIfNeeded($event)"
      [provider]="provider"
      (didChangeProvider)="changeProvider($event)"
    />
    @if (provider !== 'email' && (store.$remoteConfig().googleAuthEnabled || mode() === 'login')) {
      <app-google-auth
        [mode]="mode()"
        (authResult)="collectConsentIfNeeded($event)"
        [provider]="provider"
        (didChangeProvider)="changeProvider($event)"
      />
    }
    @if (provider !== 'email') {
      <app-apple-auth
        [mode]="mode()"
        (authResult)="collectConsentIfNeeded($event)"
        [provider]="provider"
        (didChangeProvider)="changeProvider($event)"
      />
    }
  </div>

  @if (showConsent) {
    <div>
      <app-consent (accept)="onAcceptConsent($event)" (decline)="onDeclineConsent()" />
    </div>
  }
</div>
